html {
  width: 100%;
  height: 100%;
}

body {
  color: #004057 !important;
  width: 100%;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  width: 100%;
  height: 100%;
}

.MuiBackdrop-root {
  background: linear-gradient(
    100deg,
    rgba(0, 64, 87, 0.7) 0%,
    rgba(0, 64, 87, 1) 100%
  );
}

.flex {
  display: flex;
}

.items-center {
  display: flex;
  align-items: center;
}

.items-justify-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.flex-grow {
  flex-grow: 1;
}

.flex-col {
  flex-direction: column;
}

.row-wrap {
  flex-wrap: wrap;
}

.text-center {
  text-align: center;
}

.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 50px;
}
